import React, { useCallback, useEffect, useState, Fragment } from "react";
import EasySpeech from 'easy-speech'
import Headset from '@material-ui/icons/Headset';
import Cancel from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
}));

const offset = 3000

const Lector = ({texto}:{texto:string}) => {
  const classes = useStyles();
  const [allowedSpeech, setAllowedSpeech] = useState<boolean>(false)
  const [playing, setPlaying] = useState<boolean>(false)
  const [paused, setPaused] = useState<boolean>(false)
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([])
  const [parts, setParts] = useState<string[]>([])
  const [currentPart, setCurrentPart] = useState<number>(-1)
  // const populateVoiceList = useCallback(() => {
  //   const v = synth.getVoices();
  //   const spanishVoices = v.filter(i => i.lang === "es" || i.lang === "es-ES")
  //   console.log("spanishVoices", spanishVoices)
  //   setVoices(spanishVoices);
  // }, []);
  

  const getParts = (txt:string) => {
    if (txt.length < offset) {
      return [txt]
    }
    const maxIndex = Math.floor(txt.length / offset)
    const restChars = txt.length % offset
    const arr = []
    for (let index = 0; index <= maxIndex; index++) {
      arr.push(txt.substring(index*offset, index*offset + offset ))
      if (index === maxIndex && restChars > 0) {
        arr.push(txt.substring(index*offset + offset))
      }
    }
    return arr
  }
  
  useEffect(() => {
    // populateVoiceList();
    // if (synth.onvoiceschanged !== undefined) {
    //   synth.onvoiceschanged = populateVoiceList;
    // }
    EasySpeech.init({ maxTimeout: 5000, interval: 250 })
    .then(() => {
      // console.log('load complete')
      setAllowedSpeech(true)
      setVoices(EasySpeech.voices().filter(i => i.lang === "es" || i.lang === "es-ES" || i.lang === "es_ES" || i.lang === "es_MX"))
      setParts(getParts(texto))
      //console.log("parts", parts)
    })
    .catch(e => console.error(e))
  }, []);

  useEffect(() => {
    // console.log("currentPar", currentPart)
    if (currentPart>-1) {
      speech()
    }
  }, [currentPart])
  
  const speech = async () => {
    // console.log("currentpart", currentPart)
    await EasySpeech.speak({
      text: parts[currentPart],
      voice: voices[0], // optional, will use a default or fallback
      pitch: 1,
      rate: 1,
      volume: 1,
      // there are more events, see the API for supported events
      // boundary: e => console.debug('boundary reached'),
      end: async e => {
        if (parts[currentPart+1]) {
          setCurrentPart(currentPart+1)
        } else {
          setPlaying(false)
        }
      },
    })
  }

  const speak = async (e) => {
    e.preventDefault();
    if (allowedSpeech) {
      if (playing) {
        setPaused(true)
        setPlaying(false)
        EasySpeech.pause()
      } else {
        if(paused) {
          setPaused(false)
          setPlaying(true)
          EasySpeech.resume()
        } else {
          setPlaying(true)
          setCurrentPart(0)
        }
      }
    }
  }

  return (
    // <select
    //   value={selected}
    //   onChange={(e) => setSelected(parseInt(e.target.value))}
    // >
    //   {voices.map((voice, index) => (
    //     <option key={index} value={index}>
    //       {voice.name} ({voice.lang}) {voice.default && ' [Default]'}
    //     </option>
    //   ))}
    // </select>
    <Fragment>
      {
        allowedSpeech && (
          <div className={classes.buttonContainer}>
            <button
              className={classes.button}
              onClick={(e) => speak(e)}
            >
              {
                !playing && (
                  <Headset />
                )
              }
              {
                playing && (
                  <Cancel />
                )
              }
            </button>
            <span>
              Escuchar
            </span>
            {/* <div style={{ flexDirection: 'column' }}>
              {
                EasySpeech.voices().map(i=> (<div><span>{i.lang}</span></div>))
              }
            </div> */}
          </div>
        )
      }
    </Fragment>
  )
}

export default Lector
