import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import moment from 'moment';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SEOArticle from '../components/SEOArticle.js';
import Layout from "../components/layout";
import Nota from "../components/Nota";
import SectionCard from '../components/SectionCard';
import ShareButtons from "../components/ShareButtons";
import NotaImage from "../components/NotaImage";
import archivoIMG from '../assets/defaults/archivo.png'

import banner from '../assets/banners/agenda/canal-po-whatsapp.jpg';
import Lector from '../components/Lector';
import { autorModifier } from '../utils/tools'

const { getArrayAutores } = require('../utils/tools.js');
const useStyles = makeStyles(theme => ({
    boxContenedor:{
        position:'relative',
        display:'flex',
        justifyContent:'center',
        flexWrap: 'wrap',
        // zIndex: 10,
    },
    boxContenido:{
      zIndex: 10,
        // backgroundColor:`#F5F5F5`,
        backgroundColor: 'transparent',
        marginBottom:'2rem',
        //marginTop:"18rem",
        paddingBottom:"2rem",
        paddingLeft:'1rem',
        paddingRight:'1rem',
        paddingTop:'2rem',
        position:'relative',
        width:'50%',
        [theme.breakpoints.down('md')]: {
            width:'70%',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop:0,
            width:'100%',
        },
    },
    boxTitular:{
        textAlign:'center',
    },
    tituloNota:{
        fontFamily:"FuturaBold",
        fontSize:'2.6rem',
        wordWrap:"normal",
        marginTop: "1rem",
        textShadow: '1px 1px white',
        [theme.breakpoints.down('md')]:{
            marginTop:'1rem !important',
            fontSize:'2.6rem !important',
            lineHeight:'2.9rem !important',
        },
        [theme.breakpoints.down('sm')]:{
            marginTop:'0.5rem !important',
            fontSize:'1.5rem !important',
            lineHeight:'1.7rem !important',
        }
    },
    imgPrincipal:{
      width:'100%',
      height:'auto',
      //height: 0,
      //paddingTop: '56.25%', // 16:9
      position: 'relative',
      zIndex: 1,
    },
    coverDegrade: {
        content: '""',
        display: 'block',
        position: 'absolute',
        background: 'linear-gradient(to bottom, transparent 40%, white 100%)',
        zIndex: 3,
        fallbacks: {
          background: 'red'
        },
        width: '100%',
        opacity: '.8',
        top: 0,
        bottom: 0
    },
    imgCover: {
      height:"36rem",
      overflow:"hidden",
      position:"absolute",
      width:"100%",
      // zIndex: 1,
    },
    escribe:{
      marginTop:"1rem !important",
      fontSize:"1rem !important",
      textDecoration:"underline",
      color:"black",
      fontWeight: 600,
    },
    escribe_label:{
      marginTop:"1rem !important",
      fontSize:"1rem !important",
      fontWeight: 600,
    },
    subhead:{
      marginTop:'1rem',
      textAlign:'center',
      fontSize:'1.3rem !important',
      fontStyle:'italic',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize:'1.2rem !important',
        padding:'0 1rem',
      },
    },
    volanta:{
      marginTop:'1rem',
      width: '100%',
      textShadow: '1px 1px white',
      // textAlign:'center',
      fontSize:'1.3rem !important',
      fontWeight: 600,
      //fontStyle:'italic',
      [theme.breakpoints.down('sm')]: {
        fontSize:'1.2rem !important',
        padding:'0 1rem',
      },
    },
    fecha:{
      display: 'block',
      fontWeight: 600,
      marginTop:'1rem',
      marginBottom:'1rem',
      fontSize:".9rem !important",
      [theme.breakpoints.down('sm')]: {
        //marginTop:" 0rem !important",
      },
    },
    timeToRead:{
      marginTop: "1rem",
      textAlign: "center",
      fontSize:".7rem",
      fontWeight: 600,
    },
    seccion:{
      display: "inline",
      position: "static"
    },
    cuerpo:{
        marginTop:'3rem',
    },
    cuerpoTexto:{
        '& > p img': {
          height: 'auto',
          width: '100%',
        },
        '& > iframe': {
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            height: 'auto',
          },
        },
      // '&:first-letter': {
      //   fontSize: '200%',
      //   fontWeight: 'bold',
      // }
    },
    share:{
      position:"relative",
    },
    boxRevistaHeader: {
      backgroundColor:"#E5332A",
      marginTop:"2rem", 
      padding:"3rem 3.5rem", 
      display:"flex", 
      justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        padding:"1rem 1.5rem",
        marginTop:"1rem",
      },
    },
    boxRevistaListaLabel: {display:"flex",flexDirection:"column"},
    labelRevistaBoldBlack: {
      color:"black",
      fontSize:"4rem",
      fontFamily:"FuturaBold",
      lineHeight:"1.5rem",
      marginTop:"2.7rem",
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.3rem",
        marginTop:"0.3rem",
      },
    },
    labelRevistaBoldWhite: {
      color:"white",
      fontSize:"4rem",
      fontFamily:"FuturaBold",
      lineHeight:"1.5rem",
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.3rem",
      },
    },
    labelRevistaBoldWhiteLittle: {
      color:"white",
      fontFamily:"FuturaBold",
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.5rem",
      },
    },
    labelRevistaWhiteLittle: {
      color:"white",
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.5rem",
      },
    },
    contenidoRevista:{
      marginTop:"0rem",
    },
    ultimasSeccion:{
      margin:"3rem",
      [theme.breakpoints.down('sm')]:{
        margin:"1rem",
      },
      borderTop: "2px solid black",
    },
    ultimasSeccionTitulo:{  
      fontSize: "1.5rem",
      [theme.breakpoints.down('sm')]:{
        textAlign: "center",
        fontSize: "1.3rem",
      },
    },
    ultimasSeccionItem:{
      marginBottom: "3rem",
      position: 'relative',
      [theme.breakpoints.up('md')]:{
        marginLeft: '7rem',
      },
      [theme.breakpoints.up('lg')]:{
        marginLeft: '10rem',
      },
    },
    ultimasSeccionItemBorder: {
      width: '35rem',
      borderBottom: '2px solid black',
      position: 'absolute',
      left: '23%',
      bottom: -20,
      [theme.breakpoints.down('md')]:{
        left: '25%',
        width: '15rem',
      },
      [theme.breakpoints.down('sm')]:{
        left: '25%',
        width: '15rem',
      },
      [theme.breakpoints.down('xs')]:{
        left: '15%',
      },
    },
    banner: {
      marginTop: '2rem',
      width: '100%',
    },
}));

function RevistaHeader() {
  const classes = useStyles();
  return(
    <Box className={classes.boxRevistaHeader}>
      <Box className={classes.boxRevistaListaLabel}>
        <span className={classes.labelRevistaBoldWhite}>Política Obrera</span>
        <span className={classes.labelRevistaBoldBlack}>Revista</span>
      </Box>
      <Box className={classes.boxRevistaListaLabel} style={{alignSelf:"center"}}>
        <span className={classes.labelRevistaBoldWhiteLittle}>Revista teórica</span>
        <span className={classes.labelRevistaWhiteLittle}>de Política Obrera</span>
      </Box>
    </Box>
  );
};


function NotaView(props) {
  const classes = useStyles();
  const {
    html,
    excerpt,
    timeToRead,
    frontmatter: {
      title,
      seccion,
      author,
      subhead,
      image,
      volanta,
      date,
      tags
    },
  } = props.data.markdownRemark;

  const imgFluidDefault = props.data.file.childImageSharp.fluid;
  const autores = getArrayAutores(author);
  const { slug } = props.pageContext;
  const path = seccion !== 'revista' ? `${slug}` : `${seccion}/${slug}`;
  const created_date = moment( moment.utc(date)).format('DD/MM/YYYY');

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const texto = html.replace(/<[^>]+>/g, '')

  return (
    <Layout>
      <SEOArticle 
        title={title}
        author={author}
        description={subhead ? subhead : excerpt}
        date={date}
        image={seccion === 'archivo' ? {src:archivoIMG} : image}
        notaSlug={path}
        seccion={seccion}
        tags={tags}
      />
      
      {/* <Box className={classes.share}>
        <ShareButtons title={title} excerpt={excerpt} url={`https://politicaobrera.com/${slugNota}`} />
      </Box> */}
      {seccion === "revista" && <RevistaHeader />}
      <Box className={`${classes.boxContenedor}`}>
        {/* {!matches && seccion !== "revista" && seccion !== 'archivo' &&
          <Box className={classes.imgCover}>
            <NotaImage seccion={seccion} image={image} classes={classes} imgFluidDefault={imgFluidDefault} title={title} />
            <div className={classes.coverDegrade}></div>
          </Box>
        } */}


        <Box className={`${classes.boxContenido} ${seccion === "revista" || seccion === "archivo"?classes.contenidoRevista:""}`}>
          <Box className={classes.boxTitular}>
            {seccion !== "revista" && <SectionCard label={seccion} className={classes.seccion} />}
            {
              volanta && (
                <div style={{ width: '100%', marginTop: '1rem' }}>
                <Typography className={classes.volanta} component="span">
                  {volanta}
                </Typography>
              </div> 
              )
            }

            <Typography className={classes.tituloNota} component="h1">
              {title}
            </Typography>
            <Typography className={classes.escribe_label} component="p">
              {autores.length !== 0 ? (autores.length > 1 ? 'Escriben ' : 'Escribe ') : ''}
              {autores.map( (item,index) => {
                if(index === 0){
                  return (
                    <Link key={`author-${index}`} className={classes.escribe} to={`/autor/${item.slug}`}>
                      {autorModifier(item.autor)}
                    </Link>
                  );
                }
                if((index+1) === autores.length){
                    return(<>
                      {` y `}
                      <Link key={`author-${index}`} className={classes.escribe} to={`/autor/${item.slug}`}>
                        {autorModifier(item.autor)}
                      </Link>
                    </>);
                }
                return(
                  <>
                    {`, `}
                    <Link key={`author-${index}`} className={classes.escribe} to={`/autor/${item.slug}`}>
                      {autorModifier(item.autor)}
                    </Link>
                  </>)
                })
              }
            </Typography>
            <time className={classes.fecha} dateTime={date}>
              {created_date}
            </time>
            <Typography className={classes.subhead} component="p">
                {subhead}
            </Typography>
          </Box>

          {seccion !== "revista" && seccion !== "archivo" && <NotaImage image={image} classes={classes} imgFluidDefault={imgFluidDefault} title={title} />}
          <ShareButtons title={title} excerpt={excerpt} url={`https://politicaobrera.com/${path}`} />

          <Typography className={classes.timeToRead} component="p">
              {`Tiempo de lectura: ${timeToRead} ${timeToRead > 1 ? 'minutos' : 'minuto'}`}
          </Typography>
          
          <div className={classes.timeToRead}>
            <Lector texto={`${title}. ${texto}`} />
          </div>

          <Box className={classes.cuerpo}>
            <Typography className={classes.cuerpoTexto} component="div" dangerouslySetInnerHTML={{ __html: html }} />
            <a key="banner-link" target="_blank" rel="noopener norefferer" href="https://whatsapp.com/channel/0029VacZBCoBvvsabXvImk0J">
              <img className={classes.banner} src={banner} alt="Suscribite al canal de WhatsApp de Política Obrera" />
            </a>
          </Box>
          <div
            class="fb-comments"
            data-href={`https://politicaobrera.com/${path}`}
            data-width="100%"
            data-numposts="30"
            data-lazy="true"
          >
          </div>
        </Box>
      </Box>
      <aside className={classes.ultimasSeccion}>
        <div style={{display: "block", margin: "1rem 0rem", textAlign: "center"}}>
          <span className={classes.ultimasSeccionTitulo}>Más de <SectionCard label={seccion} className={classes.seccion} /></span>
        </div>
        <div style={{ marginTop: '2rem'}}>
          {props.data.ultimasSeccion.edges.map(
            (item, idx)=>(
              <div
                key={`ultimas-x-seccion-${idx}`}
                className={`${classes.ultimasSeccionItem}`}
              >
                <Nota node={item.node} imgFluidDefault={imgFluidDefault} showImage={matches ? false : true}/>
                <div className={`${idx !== 4 ? classes.ultimasSeccionItemBorder : ''}`}/>
              </div>
              // <div className={`${classes.ultimasSeccionItem} ${idx !== 4 ? classes.ultimasSeccionItemBorder : ''}`}>
              //   <NotaCard node={item.node} imgFluidDefault={imgFluidDefault} showSection={false} showImage={false} />
              // </div>
              )
            )}
        </div>
      </aside>
      {/* {
        true && (
          <div className={classes.timeToRead}>
            <Lector texto={`${title}. ${texto}`} />
          </div>
        )
      } */}
    </Layout>
  );
}

export const query = graphql`
  query($slug: String, $seccion: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        excerpt
        timeToRead
        frontmatter {
          title
          seccion
          date
          author
          subhead
          volanta
          image
          tags
        }
        fields{
          slug
        }
    }
    file(sourceInstanceName: {eq: "defaults"}, name: {eq: "default1"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ultimasSeccion: allMarkdownRemark(limit: 5, filter: {frontmatter: {seccion: {eq: $seccion}}, fields: {slug: {ne: $slug}}}, sort: {fields: frontmatter___date, order: DESC}){      
      edges {
        node {
          frontmatter {
            author
            seccion
            title
            date
            image
          }
          fields{
            slug
          }
        }
      }
    }
  }
`;

export default NotaView;
