import React from "react";
import Helmet from "react-helmet";
import {slug} from '../utils/tools'
import { StaticQuery, graphql } from "gatsby";

const ARTICLE_IMG_URL = 'https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes'

function SEOArticle({ author, date, description, image: metaImage, title, seccion, notaSlug, tags }) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
              keywords
              siteUrl
              twAuthor
              secciones
            }
          }
          featuredImage: file(
            absolutePath: { glob: "**/src/assets/logo/POLogo.png" }
          ) {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      `}
      render={data => {
        const meta = [];
        const metaTitle = `${title} - ${data.site.siteMetadata.title}`
        const metaDescription = description ? `${description} - ${data.site.siteMetadata.title}`:`Nota en seccion ${seccion} de Política Obrera - ${data.site.siteMetadata.description}`;
        const ogUrl = `${data.site.siteMetadata.siteUrl}/${notaSlug}`
        const ogType = 'article'

        // OG adding authors and publisher
        meta.push({
          property: "og:article:publisher",
          content: data.site.siteMetadata.title
        })
        author.map(i => meta.push({
          property: "og:article:author",
          content: i
        }))
        
        // OG adding seccion
        const seccionName = data.site.siteMetadata.secciones.filter(item => slug(item) === seccion)[0]
        meta.push(
          {
            property: "og:article:section",
            content: seccionName
          },          
        )

        // OG adding tags
        if(tags){
          tags.map(i => meta.push({
            property: "og:article:tag",
            content: i
          }))
        }

        let authorStructured
        //armo data structured para autores
        if (author) {
          if (author.length > 0) {
            authorStructured = author.map((i) => {
              return {
                "@type": "Person",
                name: i,
              }
            })
          } else {
            authorStructured = [{
              "@type": "Organization",
              "name": "Política Obrera",
            }]
          }
        }

        const image = metaImage ? `${metaImage.src ? `${data.site.siteMetadata.siteUrl}${metaImage.src}` : `${ARTICLE_IMG_URL}/${metaImage}`}` : `${data.site.siteMetadata.siteUrl}${data?.featuredImage?.childImageSharp?.original?.src}`
        
        let schema, schemaAsString

        schema = {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": ogUrl
          },
          headline: title,
          image: [image],
          datePublished: date,
          author: authorStructured,
          "publisher": {
            "@type": "Organization",
            "name": "Política Obrera",
          },
          description,    
        };

        schemaAsString = JSON.stringify(schema, null, 2);

        return(
          <Helmet
            htmlAttributes={{
              lang: "es"
            }}
            title={metaTitle}
            link={
              [
                {
                  rel:"canonical",
                  href:ogUrl,
                }
              ]
            }
            meta={
              [
                {
                  name: "description",
                  content: metaDescription
                },
                {
                  name: "keywords",
                  content: data.site.siteMetadata.keywords
                },
                {
                  property: "og:site_name",
                  content: "Política Obrera"
                },                
                {
                  property: "og:type",
                  content: ogType
                },
                {
                  property: "og:title",
                  content: metaTitle
                },
                {
                  property: "og:description",
                  content: metaDescription
                },
                {
                  property: "og:url",
                  content: ogUrl
                },
                {
                  name: "twitter:creator",
                  content: data.site.siteMetadata.twAuthor
                },
                {
                  name: "twitter:title",
                  content: title
                },
                {
                  name: "twitter:description",
                  content: metaDescription
                }
              ]
              .concat(
                image
                ? [
                  {
                    property: "og:image",
                    content: image
                  },
                  {
                    property: "og:image:alt",
                    content: metaTitle
                  },                  
                  {
                    property: "og:image:width",
                    content: 300
                  },
                  {
                    property: "og:image:height",
                    content: 300
                  },
                  {
                    property: "og:image:url",
                    itemprop: "image",
                    content: image
                  },                      
                  {
                    property: "twitter:image",
                    content: image
                  },                      
                  {
                    name: "twitter:image:alt",
                    content: metaTitle
                  },                
                  {
                    name: "twitter:card",
                    content: "summary_large_image"
                  }
                ]
                : [
                  {
                    name: "twitter:card",
                    content: "summary"
                  }
                ]
                )
              .concat(meta)
            }
          >
            {
              schemaAsString && <script type="application/ld+json">{schemaAsString}</script>
            }
          </Helmet>
        );
      }}
    />
  );
}

export default SEOArticle;
