import PropTypes from "prop-types";
import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
// import { FaFacebook, FaTwitter, FaWhatsapp, FaTelegram } from 'react-icons/fa';

// import { ShareButtonOutline, ShareBlockStandard } from "react-custom-share";

import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon
} from 'react-share';


const useStyles = makeStyles(theme => ({
  shareButtonContainer:{
    display:'flex',
    right:"0",
    zIndex:"10",
    justifyContent:'center',
  },
  shareButton:{
    cursor: "pointer",
    margin:"5px"
  },
}));

const ShareButtons = props => {
   const { url, title } = props;
   const classes = useStyles();

//   const shareBlockProps = {
//     url: url,
//     button: ShareButtonOutline,
//     buttons: [
//       { network: "Twitter", icon: FaTwitter },
//       { network: "Facebook", icon: FaFacebook },
//       { network: "Telegram", icon: FaTelegram },
//       { network: "Whatsapp", icon: FaWhatsapp },

//     ],
//     text: title,
//     longtext: excerpt,
//   };

   return(
     <Box className={classes.shareButtonContainer}>
      <FacebookShareButton
        url={url}
        quote={title}
        className={classes.shareButton}
      >
        <FacebookIcon
          size={32}
          round
        />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        className={classes.shareButton}
      >
        <TwitterIcon
          size={32}
          round
        />
      </TwitterShareButton>
      <TelegramShareButton
        url={url}
        title={title}
        className={classes.shareButton}
      >
        <TelegramIcon
          size={32}
          round
        />
      </TelegramShareButton>
      <WhatsappShareButton
        url={url}
        title={title}
        separator=":: "
        className={classes.shareButton}
      >
        <WhatsappIcon
          size={32}
          round
        />
      </WhatsappShareButton>
     </Box>
   );
 };

ShareButtons.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string
};

ShareButtons.defaultProps = {
  url: "https://politicaobrera.com/",
  title: "Política Obrera",
  excerpt: "Por un partido obrero - sitio de Política Obrera"
};

export default ShareButtons;
