import React from 'react';
import LazyLoad from 'react-lazyload';
import Img from "gatsby-image";

const NotaImage = ({ image, classes, imgFluidDefault, title }) => (
  image
    ? (
      <LazyLoad height={200}>
        <img
          alt={title}
          src={`https://storage.googleapis.com/altamiraresponde-6a16f.appspot.com/imagenes/${image}`}
          className={classes.imgPrincipal}
        />
      </LazyLoad>
    )
    : (
      <Img fluid={imgFluidDefault} className={classes.imgPrincipal} />
    )
);

export default NotaImage;
